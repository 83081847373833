import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';

export const Contact = () => {
  const { t } = useTranslation();

  const form = React.useRef<HTMLFormElement>(null!); //https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/hooks/#useref

  const sendEmail = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (window.confirm(t('contacts-confirm'))) {
      emailjs
        .sendForm('service_tjam2vh', 'template_5ttx42d', form.current, 'yVJLivsoe2khtnXMf')
        .then(
          (result) => {
            alert(t('contacts-sent'));
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          },
        );
    }
  };

  return (
    <section className="section contact" id="contacts">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading">
              <h2>{t('contacts')}</h2>
            </div>
          </div>

          <div className="col-md-12">
            <div className="section-heading">
              <p style={{ color: '#fff', fontSize: '15px', lineHeight: '28px' }}>
                <Trans components={{ br: <br />, h5: <h5 />, em: <em /> }}>contacts-address</Trans>
              </p>
              <p className="contact__social">
                <a
                  href="https://www.facebook.com/profile.php?id=100085533278633"
                  title="Facebook"
                  target="_blank">
                  <i className="fa fa-facebook-official" aria-hidden="true"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCPmoonM4rZ1uZ7_fymz5yeA"
                  title="Youtube"
                  target="_blank">
                  <i className="fa fa-youtube-play" aria-hidden="true"></i>
                </a>
                <a href="https://www.instagram.com/cf_help_ua" title="Instagram" target="_blank">
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </a>
              </p>
            </div>
          </div>

          <div className="col-md-6">
            <form ref={form} onSubmit={sendEmail}>
              <div className="row">
                <div className="col-md-6">
                  <fieldset>
                    <input
                      name="user_name"
                      type="text"
                      className="form-control"
                      placeholder={t('contacts-name')}
                      required
                    />
                  </fieldset>
                </div>
                <div className="col-md-6">
                  <fieldset>
                    <input
                      name="user_email"
                      type="email"
                      className="form-control"
                      placeholder={t('contacts-email')}
                      required
                    />
                  </fieldset>
                </div>
                <div className="col-md-12">
                  <fieldset>
                    <textarea
                      name="message"
                      rows={6}
                      className="form-control"
                      placeholder={t('contacts-msg')}
                      required></textarea>
                  </fieldset>
                </div>
                <div className="col-md-12">
                  <fieldset>
                    <button type="submit" className="button">
                      {t('contacts-btn')}
                    </button>
                  </fieldset>
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-6">
            <div id="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3098.2879841258346!2d28.413307477856783!3d49.232056739317834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x472d5c5c317ca5e7%3A0x9d71a192e9162656!2z0L_RgNC-0YHQvy4g0K7QvdC-0YHRgtC4LCA20JAsINCS0LjQvdC90LjRhtCwLCDQktC40L3QvdC40YbQutCw0Y8g0L7QsdC70LDRgdGC0YwsIDIxMDAw!5e0!3m2!1sru!2sua!4v1663091435441!5m2!1sru!2sua"
                width="100%"
                height="422px"
                frameBorder="0"
                style={{ border: '0' }}
                allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
