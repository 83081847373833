import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const PrivacyPolicy = () => {
  const { t } = useTranslation();

  document.title = t('privacy');

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <section className="section donate">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading">
              <h2>{t('privacy')}</h2>
            </div>
          </div>
          <div
            className="col-md-12"
            style={{ color: 'white', textAlign: 'justify', fontSize: '16px' }}>
            <Trans components={{ em: <em />, p: <p />, h3: <h3 />, h4: <h4 />, a: <a /> }}>
              privacy-text
            </Trans>
          </div>
        </div>
      </div>
    </section>
  );
};
