import { useTranslation } from 'react-i18next';
import { About, Contact, HelpForm, MainBanner, Projects, Volunteers } from '../components';

export const Home = () => {
  const { t } = useTranslation();

  document.title = t('footer-title');
  return (
    <>
      <MainBanner />
      <About />
      <Volunteers />
      <Projects />
      <HelpForm />
      <Contact />
    </>
  );
};
