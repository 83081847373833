import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';

import styles from './Donate.module.scss';
import { Link } from 'react-router-dom';

export const Donate = () => {
  const { t } = useTranslation();
  document.title = t('donate-title');

  const [donationField, setDonationField] = React.useState({
    whoHelp: '',
    methodPay: '',
    currencyPay: '',
  });

  const [confirmRules, setConfirmRules] = React.useState(false);

  const rulesRef = React.useRef<HTMLInputElement>(null!);

  const onChangeRules = () => {
    setConfirmRules(rulesRef.current.checked);
  };

  const onChangeWhoHelp = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDonationField({
      ...donationField,
      whoHelp: event.target.value,
    });
  };

  const onChangeMethodPay = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDonationField({
      ...donationField,
      methodPay: event.target.value,
    });
  };

  const onChangeCurrencyPay = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDonationField({
      ...donationField,
      currencyPay: event.target.value,
    });
  };

  return (
    <section className="section donate">
      <Helmet>
        <script>
          {`
          gtag('event', 'conversion', {'send_to': 'AW-855829975/1s37CPKC_-QDENfbi5gD'})
          `}
        </script>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading">
              <h2>{t('donate-title')}</h2>
            </div>
          </div>
          <div className="col-md-12 align-self-center">
            <form className={styles.root}>
              <div className="row">
                {/* <div className="col-md-12 ">
                  <div className={`${styles.radioInputs} who-help`}>
                    <div>
                      <label>
                        <input
                          onChange={onChangeWhoHelp}
                          value={'army'}
                          name="who-help"
                          type="radio"
                          className="form-control"
                          required
                        />{' '}
                        {t('donate-army')}
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          onChange={onChangeWhoHelp}
                          value={'med'}
                          name="who-help"
                          type="radio"
                          className="form-control"
                          required
                        />{' '}
                        {t('donate-med')}
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          onChange={onChangeWhoHelp}
                          value={'fond'}
                          name="who-help"
                          type="radio"
                          className="form-control"
                          required
                        />{' '}
                        {t('donate-fond')}
                      </label>
                    </div>
                  </div>
                </div> */}

                {/* {donationField.whoHelp && ( */}
                <>
                  <div className="col-md-12 ">
                    <div className={`${styles.radioInputs} method-pay`}>
                      <div>
                        <label>
                          <input
                            onChange={onChangeMethodPay}
                            value={'kredit'}
                            name="method-pay"
                            type="radio"
                            className="form-control"
                            required
                          />{' '}
                          {t('donate-kredit')}
                        </label>
                      </div>

                      {/* <div>
                          <label>
                            <input
                              onChange={onChangeMethodPay}
                              value={'crypto'}
                              name="method-pay"
                              type="radio"
                              className="form-control"
                              required
                            />{' '}
                            Crypto
                          </label>
                        </div> */}

                      <div>
                        <label>
                          <input
                            onChange={onChangeMethodPay}
                            value={'bank'}
                            name="method-pay"
                            type="radio"
                            className="form-control"
                            required
                          />{' '}
                          {t('donate-bank')}
                        </label>
                      </div>
                    </div>
                  </div>

                  {donationField.methodPay === 'bank' && (
                    <div className="col-md-12 ">
                      <div className="left-content">
                        <span>{t('donate-bank-subtitle')}</span>
                        <h4>
                          <Trans components={{ em: <em /> }}>donate-bank-title</Trans>
                        </h4>
                        <Trans components={{ p: <p />, br: <br />, h5: <h5 />, em: <em /> }}>
                          donate-bank-text
                        </Trans>

                        <div className="main-button">
                          <HashLink target="_blank" to="/assets/doc/swift.pdf">
                            {t('donate-bank-btn')}
                          </HashLink>
                        </div>
                      </div>
                    </div>
                  )}

                  {donationField.methodPay === 'crypto' && (
                    <div className="col-md-12 ">
                      <div className="left-content">
                        <h4>
                          Зробити внесок <em>за допомогою криптовалюти</em>
                          {/* <Trans components={{ em: <em /> }}>donate-bank-title</Trans> */}
                        </h4>

                        <p>
                          QR-code
                          <br />
                          <br />
                          <em> Bitcoin (BTC):</em> bc54ds65f4s6d5f4s6d54fs654dfs654fd65f4krsn3
                        </p>

                        <p>
                          QR-code
                          <br />
                          <br />
                          <em> Ethereum (ETH):</em> 0x4ds65f4s6d5f4s6d54fs654dfs654fd65f4krsn3
                        </p>

                        <p>
                          QR-code
                          <br />
                          <br />
                          <em> Solana (SOL):</em> 0x4ds65f4s6d5f4s6d54fs654dfs654fd65f4krsn3
                        </p>

                        <div className="main-button"></div>
                      </div>
                    </div>
                  )}

                  {donationField.methodPay === 'kredit' && (
                    <>
                      {/* <div className="col-md-12 ">
                          <div className={`${styles.radioInputs} currency-pay`}>
                            <div>
                              <label>
                                <input
                                  onChange={onChangeCurrencyPay}
                                  value={'UAH'}
                                  name="currency-pay"
                                  type="radio"
                                  className="form-control"
                                  required
                                />{' '}
                                UAH
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  onChange={onChangeCurrencyPay}
                                  value={'USD'}
                                  name="currency-pay"
                                  type="radio"
                                  className="form-control"
                                  required
                                />{' '}
                                USD
                              </label>
                            </div>
                            <div>
                              <label>
                                <input
                                  onChange={onChangeCurrencyPay}
                                  value={'bank'}
                                  name="currency-pay"
                                  type="radio"
                                  className="form-control"
                                  required
                                />{' '}
                                EUR
                              </label>
                            </div>
                          </div>
                        </div> */}

                      {/* {donationField.currencyPay && ( */}
                      <div className="col-md-12 ">
                        <fieldset>
                          <label style={{ textAlign: 'center', color: 'white' }}>
                            <input
                              ref={rulesRef}
                              onChange={onChangeRules}
                              checked={confirmRules}
                              name="rules"
                              type="checkbox"
                              className="form-control"
                              required
                            />{' '}
                            <Trans components={{ a: <a /> }}>donate-confirm</Trans>
                          </label>

                          <div className="main-button button">
                            <a
                              href="https://www.ipay.ua/en/bills/bf-help-ua"
                              target="_blank"
                              style={
                                !confirmRules
                                  ? {
                                      marginTop: '10px',
                                      pointerEvents: 'none',
                                      filter: 'grayscale(100%)',
                                    }
                                  : {
                                      marginTop: '10px',
                                    }
                              }>
                              {t('donate-kredit-btn')}
                            </a>
                          </div>
                        </fieldset>
                      </div>
                      {/* )} */}
                    </>
                  )}
                </>
                {/* )} */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
