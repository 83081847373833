import React from 'react';
import { Outlet } from 'react-router-dom';

import { Header, Footer, GoTop } from '../components';

const MainLayout: React.FC = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <GoTop />
    </>
  );
};

export default MainLayout;
