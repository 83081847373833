import React from 'react';
import { HashLink } from 'react-router-hash-link';
import styles from './GoTop.module.scss';

export const GoTop: React.FC = () => {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      'scroll',
      () => {
        if (window.scrollY > 800) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { passive: true },
    );
  }, []);

  return (
    <div className={`${styles.goTop}`} style={isVisible ? {} : { display: 'none' }}>
      <HashLink smooth to="#"></HashLink>
    </div>
  );
};
