import { useTranslation } from 'react-i18next';

export const Thanks = () => {
  const { t } = useTranslation();
  //document.title = t('404');

  return (
    <section className="section donate">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center" style={{ color: 'white' }}>
            <div style={{ fontSize: '11vw' }}>Thank you!</div>
            <img
              src="/img/volunteers3.webp"
              alt="Thank you!"
              width="550px"
              height="367px"
              style={{ width: '100%', objectFit: 'cover' }}
            />
            <br /> <br />
            <p style={{ fontSize: '20px', textAlign: 'justify' }}>
              Thank you for your great generosity! We, at Charity Foundation "HELP UA", greatly
              appreciate your donation, and your sacrifice. Your support helps to further our
              mission.
              <br /> <br />
              Your support is invaluable to us, thank you again! If you have specific questions
              about our mission be sure to visit our social media:{' '}
              <a
                href="https://www.facebook.com/profile.php?id=100085533278633"
                title="Facebook"
                target="_blank">
                <i className="fa fa-facebook-official" aria-hidden="true"></i>
              </a>{' '}
              <a
                href="https://www.youtube.com/channel/UCPmoonM4rZ1uZ7_fymz5yeA"
                title="Youtube"
                target="_blank">
                <i className="fa fa-youtube-play" aria-hidden="true"></i>
              </a>{' '}
              <a href="https://www.instagram.com/cf_help_ua" title="Instagram" target="_blank">
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </a>
              , or reach out to <em>{process.env.REACT_APP_EMAIL}</em>!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
