import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="footer__body">
        <div className="footer__left">
          <h4>
            CO <em>"Help UA"</em>
          </h4>
          <img src="/img/logo-visa.webp" width="74px" height="24px" alt="visa" />{' '}
          <img src="/img/logo-mastercard.webp" width="135px" height="24px" alt="mastercard" />
        </div>

        <div className="footer__right">
          <Trans components={{ em: <em />, br: <br /> }}>footer-right</Trans>
          <Link to="/privacy">{t('privacy')}</Link> <Link to="/offer">{t('offer')}</Link>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p>
              <i className="fa fa-copyright"></i> Copyright 2022
              <a href="https://cohelpua.org/"> {t('footer-title')}</a> All rights reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
