import { useTranslation } from 'react-i18next';
import { Tabs } from './Tabs';

export const Projects = () => {
  const { t } = useTranslation();
  return (
    <section className="section our-projects" id="projects">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading">
              <h2>{t('projects')}</h2>
            </div>
          </div>
          <div className="col-md-12">
            <Tabs />
          </div>
        </div>
      </div>
    </section>
  );
};
