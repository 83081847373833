import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './BtnAnimated.module.scss';

export const BtnAnimated = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <Link to="/donate">
        <span> </span>
        <span> </span>
        <span> </span>
        <span> </span>
        {t('donate')}
      </Link>
    </div>
  );
};
