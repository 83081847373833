import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const MainBanner = () => {
  const { t } = useTranslation();

  const [showContent, setShowContent] = React.useState([false, false, false]);
  const onClickContentHide = (i: number) => {
    const defaultArr = [false, false, false];
    defaultArr[i] = !showContent[i];
    setShowContent(defaultArr);
  };

  return (
    <>
      <section className="section main-banner" id="home">
        <video autoPlay muted loop playsInline id="bg-video">
          <source src="assets/images/head-video.mp4" type="video/mp4" />
          {/* course-video.mp4 poster="" */}
        </video>
        <div className="video-overlay header-text">
          <div className="caption">
            <h6>{t('subtitle')}</h6>
            <h2>
              <Trans components={{ em: <em /> }}>title</Trans>
            </h2>
          </div>
        </div>
      </section>
      <section className="features">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="features-post">
                <div onClick={() => onClickContentHide(0)} className="features-content">
                  <div className="content-show">
                    <h4>
                      <i className="fa fa-info"></i>
                      {t('home-prevent')}
                    </h4>
                  </div>
                  <div className="content-hide" style={showContent[0] ? { display: 'block' } : {}}>
                    <p>{t('home-prevent-desc')}</p>
                    <p className="hidden-sm">{t('home-prevent-desc')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="features-post second-features">
                <div className="features-content" onClick={() => onClickContentHide(1)}>
                  <div className="content-show">
                    <h4>
                      <i className="fa fa-heartbeat"></i>
                      {t('home-help')}
                    </h4>
                  </div>
                  <div className="content-hide" style={showContent[1] ? { display: 'block' } : {}}>
                    <p>{t('home-help-desc')}</p>
                    <p className="hidden-sm">{t('home-help-desc')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="features-post third-features">
                <div className="features-content" onClick={() => onClickContentHide(2)}>
                  <div className="content-show">
                    <h4>
                      <i className="fa fa-ambulance"></i>
                      {t('home-save')}
                    </h4>
                  </div>
                  <div className="content-hide" style={showContent[2] ? { display: 'block' } : {}}>
                    <p>{t('home-save-desc')}</p>
                    <p className="hidden-sm">{t('home-save-desc')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
