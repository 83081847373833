import { useTranslation } from 'react-i18next';

export const NotFound = () => {
  const { t } = useTranslation();
  document.title = t('404');
  return (
    <section className="section donate">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center" style={{ color: 'white' }}>
            <div style={{ fontSize: '25vw' }}>404</div>
            <p style={{ fontSize: '25px' }}>{t('404')}</p>
          </div>
        </div>
      </div>
    </section>
  );
};
