import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styles from './Tabs.module.scss';

const projects = [
  {
    name: 'project1-name',
    title: 'project1-title',
    description: 'project1-desc',
    img: '/img/volunteers.webp',
    link: '',
  },
  {
    name: 'project2-name',
    title: 'project2-title',
    description: 'project2-desc',
    img: '/img/volunteers3.webp',
    link: '',
  },
];

export const Tabs: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.tabs}`}>
      {projects &&
        projects.map(({ name, title, description, img, link }, index) => (
          <div key={name + index}>
            <input
              name="tabs"
              type="radio"
              id={`tab-${index}`}
              defaultChecked={index === 0 ? true : false}
            />
            <label className="nav-item nav-link" htmlFor={`tab-${index}`}>
              {t(name)}
            </label>
            <div className={styles.tabs__panel}>
              <img src={img} alt={t(name)} width="504px" height="368px" />
              <div>
                <h4>
                  <Trans components={{ em: <em /> }}>{title}</Trans>
                </h4>
                <Trans components={{ p: <p /> }}>{description}</Trans>

                {link && <a href={link}>{t('projects-link-text')}</a>}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
