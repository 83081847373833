import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';

export const HelpForm = () => {
  const { t } = useTranslation();

  const form = React.useRef<HTMLFormElement>(null!); //https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/hooks/#useref

  const sendEmail = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (window.confirm(t('hf-confirm'))) {
      emailjs
        .sendForm('service_tjam2vh', 'template_dh2fylz', form.current, 'yVJLivsoe2khtnXMf')
        .then(
          (result) => {
            alert(t('hf-sent'));
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          },
        );
    }
  };

  return (
    <section className="section coming-soon" id="help-form">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="right-content">
              <div className="top-content">
                <h4>
                  <Trans components={{ em: <em /> }}>hf-title</Trans>
                </h4>
                <h6>
                  <Trans components={{ em: <em />, br: <br /> }}>hf-desc</Trans>
                </h6>
              </div>

              <form ref={form} onSubmit={sendEmail}>
                <div className="row">
                  <div className="col-md-12 ">
                    <div className="radio-inputs">
                      <div className="col-md-6">
                        <label>
                          <input
                            name="person"
                            value={t('hf-phys-person')}
                            type="radio"
                            className="form-control"
                            required
                          />{' '}
                          {t('hf-phys-person')}
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label>
                          <input
                            name="person"
                            value={t('hf-jur-person')}
                            type="radio"
                            className="form-control"
                            required
                          />{' '}
                          {t('hf-jur-person')}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <fieldset>
                      <input
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder={t('hf-pib')}
                        required
                      />
                    </fieldset>
                  </div>
                  <div className="col-md-12">
                    <fieldset>
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder={t('hf-email')}
                        required
                      />
                    </fieldset>
                  </div>
                  <div className="col-md-12">
                    <fieldset>
                      <input
                        name="phone"
                        type="tel"
                        className="form-control"
                        placeholder={t('hf-phone')}
                        required
                      />
                    </fieldset>
                  </div>

                  <div className="col-md-12 ">
                    <div className="checkbox-inputs">
                      <h6>{t('hf-type')}</h6>
                      <div className="checkbox-inputs__item">
                        <div className="col-md-6">
                          <label>
                            <input name="humanitarian" type="checkbox" className="form-control" />{' '}
                            {t('hf-hum')}
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label>
                            <input name="medical" type="checkbox" className="form-control" />{' '}
                            {t('hf-med')}
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label>
                            <input name="tactical" type="checkbox" className="form-control" />{' '}
                            {t('hf-tact')}
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label>
                            <input name="donation" type="checkbox" className="form-control" />{' '}
                            {t('hf-donat')}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <fieldset>
                      <textarea
                        name="additionally"
                        rows={6}
                        className="form-control"
                        placeholder={t('hf-addition')}></textarea>
                    </fieldset>
                  </div>

                  <div className="col-md-12">
                    <fieldset>
                      <button type="submit" className="button">
                        {t('hf-btn')}
                      </button>
                    </fieldset>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
