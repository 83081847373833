import { HashLink } from 'react-router-hash-link';
import { Trans, useTranslation } from 'react-i18next';

export const About = () => {
  const { t } = useTranslation();

  return (
    <section className="section about" id="about">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading">
              <h2>{t('about')}</h2>
            </div>
          </div>
          <div className="col-md-6 align-self-center">
            <div className="left-content">
              <span>{t('about-subtitle')}</span>
              <h4>
                <Trans components={{ em: <em /> }}>about-title</Trans>
              </h4>
              <p>{t('about-text')}</p>
              <div className="main-button">
                <HashLink smooth to="/#contacts">
                  {t('contacts')}
                </HashLink>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <article className="video-item">
              <div className="video-caption">
                <h4>{t('about-img-title')}</h4>
              </div>
              <figure>
                <img src="/img/pro-nas.webp" width="540px" height="405px" />
              </figure>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};
