import { Trans, useTranslation } from 'react-i18next';

export const Volunteers = () => {
  const { t } = useTranslation();
  return (
    <section className="section volunteer" id="volunteers">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="volunteer__text">
              <h4>
                <Trans components={{ em: <em /> }}>volunteers-title</Trans>
              </h4>
              <img width="666px" height="444px" src="/img/volunteers2.webp" alt={t('volunteers')} />

              <Trans components={{ p: <p /> }}>volunteers-desc</Trans>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
