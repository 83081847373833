import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

import { BtnAnimated } from './BtnAnimated';
import { Link } from 'react-router-dom';

const langs = [
  { name: 'UKR', code: 'ua', img: '/assets/locales/img/ua.svg' },
  { name: 'ENG', code: 'en', img: '/assets/locales/img/us.svg' },
  { name: 'DEU', code: 'de', img: '/assets/locales/img/de.svg' },
  { name: 'FRA', code: 'fr', img: '/assets/locales/img/fr.svg' },
  { name: 'JPN', code: 'jp', img: '/assets/locales/img/jp.svg' },
];

export const Header = () => {
  const { t, i18n } = useTranslation();
  const [openMobMenu, setOpenMobMenu] = React.useState(false);
  const onClictMobMenu = () => {
    if (window.innerWidth < 951) {
      setOpenMobMenu(!openMobMenu);
    }
  };

  //Location
  const [curentLng, setCurentLng] = React.useState(localStorage.getItem('i18nextLng'));
  const changeLng = (lng: string) => {
    i18n.changeLanguage(lng);
    setCurentLng(lng);
  };

  //change Lng and slide with URL
  React.useEffect(() => {
    if (window.location.search.substring(5)) {
      const inLineLng = String(window.location.search.substring(5));
      if (langs.filter(({ code }) => code === inLineLng)[0]) {
        changeLng(inLineLng);
      }
    }

    if (window.location.hash && window.location.hash.indexOf('?') + 1) {
      const lngPosition = window.location.hash.indexOf('?');
      const inLineLng = String(window.location.hash.substring(lngPosition + 5));

      if (langs.filter(({ code }) => code === inLineLng)[0]) {
        changeLng(inLineLng);
      }

      document
        .getElementById(String(window.location.hash.substring(1, lngPosition)))
        ?.scrollIntoView();
    }

    if (window.location.hash) {
      document.getElementById(String(window.location.hash.substring(1)))?.scrollIntoView();
    }
  }, []);

  const visibleCurrentLng = langs.filter(({ code }) => code === curentLng)[0];

  return (
    <header className={`main-header clearfix js`} role="header">
      <div className="logo">
        <div className="btn-donate">
          <BtnAnimated />
        </div>
      </div>

      <Link onClick={onClictMobMenu} to="#" className={`menu-link ${openMobMenu ? 'active' : ''}`}>
        <i className="fa fa-bars"></i>
      </Link>
      <nav
        onClick={onClictMobMenu}
        id="menu"
        className={`main-nav ${openMobMenu ? 'active' : ''}`}
        role="navigation">
        <ul className="main-menu">
          <li>
            <HashLink smooth to="/#home">
              {t('home')}
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="/#about">
              {t('about')}
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="/#volunteers">
              {t('volunteers')}
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="/#projects">
              {t('projects')}
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="/#help-form">
              {t('help-form')}
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="/#contacts">
              {t('contacts')}
            </HashLink>
          </li>
          <li className="has-submenu">
            <HashLink to="#">
              <img
                width="17px"
                height="13px"
                className="flag-img"
                src={visibleCurrentLng.img}
                alt={visibleCurrentLng.name}
              />{' '}
              {visibleCurrentLng.name}
            </HashLink>
            <ul className="sub-menu">
              {langs &&
                langs
                  .filter(({ code }) => code !== curentLng)
                  .map(({ code, name, img }) => (
                    <li key={code}>
                      <HashLink onClick={() => changeLng(code)} to={`?lng=${code}`}>
                        <img width="17px" height="13px" className="flag-img" src={img} alt={name} />{' '}
                        {name}
                      </HashLink>
                    </li>
                  ))}
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
};
