import { Route, Routes } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import { Donate, Home, NotFound, OfferAgreement, PrivacyPolicy, Thanks } from './pages';

function App() {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/offer" element={<OfferAgreement />} />
        <Route path="/thanks" element={<Thanks />} />

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
